export default [
  {
    key: 'increasement',
    sortable: false,
    sortField: '',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'gameName',
    sortable: false,
    sortField: '',
    label: 'field.account_name',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'userId',
    sortable: false,
    sortField: 'amount',
    label: 'field.game_id',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'zoneId',
    sortable: false,
    sortField: 'price',
    label: 'field.zone_id',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'startDate',
    sortable: false,
    label: 'field.start_date',
    variant: 'light',
  },

  {
    key: 'duration',
    sortable: false,
    sortField: '',
    label: 'field.duration',
  },
  {
    key: 'isBan',
    sortable: false,
    sortField: '',
    label: 'field.is_ban',
  },

  {
    key: 'actions',
    label: 'general.action',
  },
]
